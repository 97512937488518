import { HubStorage } from './types';
import { rootStore } from './root';
import { localStore } from './local';
import { clientStore } from './client';
import { IConfig } from '..';
import { events, EventType } from '../events';
import { isRootOriginConfiguredAndAreWeTheRootOrigin as isRootOrigin } from '../utils/origin';
import { logger } from '../utils/log';

export let storage: HubStorage;

function computeRootIFrameSrc(config: IConfig) {
  const originPath = window.localStorage.getItem('rph_root_origin_path');

  return `${config.rootOrigin}${originPath || ''}?rownd_mode=root_storage_iframe`;
}

function createIFrame(config: IConfig): HTMLIFrameElement {
  const iframe = document.createElement('iframe');
  iframe.setAttribute('style', 'display: none;');
  iframe.setAttribute('width', '0');
  iframe.setAttribute('height', '0');
  iframe.setAttribute('src', computeRootIFrameSrc(config)!);
  iframe.setAttribute('sandbox', 'allow-scripts allow-same-origin');
  document.body.appendChild(iframe);
  return iframe;
}

export async function initStorage(config: IConfig, initCb?: () => void) {
  if (storage) {
    return storage;
  }

  // Use local store when root store is disabled
  if (process.env.ENABLE_ROOT_STORE === 'false') {
    setTimeout(() => {
      events.dispatch(EventType.STORAGE_READY, {});
    });
    storage = localStore();
    return storage;
  }

  const rootOriginStore = () => {
    // Init root store for iframe, if applicable
    const rowndMode = new URLSearchParams(window.location.search).get('rownd_mode');
    if (rowndMode === 'root_storage_iframe') {
      logger.debug('Setting up the root store');
      rootStore();
    }

    if (initCb) {
      initCb();
    }

    // This needs to fire at the end of the current event loop
    setTimeout(() => {
      events.dispatch(EventType.STORAGE_READY, {});
    });

    return localStore();
  };

  let storageProvider: HubStorage | null | undefined;
  if (config?.rootOrigin !== null && !isRootOrigin(config)) {
    const iframe = createIFrame(config);
    storageProvider = await clientStore(iframe, config, initCb);

    if (!storageProvider) {
      storageProvider = rootOriginStore();
    }
  } else if (isRootOrigin(config)) {
    storageProvider = rootOriginStore();
  }

  if (!storageProvider) {
    throw Error('Failed to initialize storage provider');
  }

  storage = storageProvider;
  return storage;
}
