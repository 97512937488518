import { createContext } from 'preact';
import { useContext } from 'preact/hooks';
import { IConfig } from '..';
import { LoginStep } from '../Login';
import { Automation } from '../Automations/types';
import { UserPasskey } from './use-passkeys';

export type GlobalState = {
  is_initializing: boolean;
  is_container_visible: boolean;
  use_modal: boolean;
  computed_color_mode: 'light' | 'dark';
  sign_in: {
    last_sign_in?: keyof SignInMethods;
    last_sign_in_date?: string;
  };
  nav: {
    current_route: string;
    route_trigger: string;
    event_id: string;
    section: string;
    options?: any;
    step?: LoginStep;
    popup_route?: string;
  };
  user: User;
  auth: Auth;
  app: {
    id?: string;
    name?: string;
    icon?: string;
    invalid_web_origins?: boolean;
    config: AppConfig | null;
    schema: AppSchema | null;
    user_verification_field?: string;
    user_verification_fields?: string[];
  };
  automations?: GlobalStateAutomations;
  is_saving_user_data: boolean;
  is_loading_user_data: boolean;
  is_waiting_for_post_authentication_api: boolean;
  is_accepting_group_invite: boolean;
  is_post_sign_in_requirements_done: boolean;
  config?: IConfig;
  android?: {
    active_accounts?: { email?: string }[];
  };
  localSettings?: localSettings;
};

interface GlobalStateAutomations {
  queue?: Automation[];
  active_automation?: string;
}

type AuthLevel = 'instant' | 'guest' | 'unverified' | 'verified';
export interface Auth {
  access_token: string | null;
  refresh_token: string | null;
  app_id: string | null;
  init_data?: Record<string, any>;
  is_verified_user?: boolean;
  auth_level?: AuthLevel;
}

export type UserData = {
  user_id?: string;
  email?: string | null;
  auth_level?: AuthLevel;
  [key: string]: any;
};

export interface User {
  data: UserData;
  meta: {
    last_passkey_registration_prompt?: string | null;
    [key: string]: any | null;
  };
  groups: UserGroup[];
  needs_refresh?: boolean;
  loaded_once?: boolean;
  redacted: string[];
  verified_data: {
    [key: string]: any | null;
  };
  passkeys: {
    is_initialized: boolean;
    registrations: UserPasskey[];
  };
  instant_user?: {
    is_initializing: boolean;
  };
  is_loading: boolean;
}

export interface UserGroup {
  group: Group;
  member: GroupMember;
}

export interface Group {
  id: string;
  name: string;
  app_id: string;
  admission_policy: string;
  created_at: string;
  updated_at: string;
  updated_by?: string;
}

export interface GroupMember {
  id: string;
  app_id: string;
  user_id: string;
  roles: string[];
  group_id: string;
  state: string;
  invited_by?: string;
  added_by?: string;
  updated_at?: Date;
  updated_by?: string;
}

export type localSettings = {
  customizations?: localSettingsCustomizations;
};

export type localSettingsCustomizations = {
  dark_mode?: DarkMode;
};

export type AppSchema = Record<string, SchemaField>;

export type SchemaField = {
  display_name: string;
  type: string;
  data_category: string;
  required: boolean;
  owned_by: string;
  user_visible: boolean;
  revoke_after: string;
  required_retention: string;
  collection_justification: string;
  opt_out_warning: string;
  read_only?: boolean;
  unique?: boolean;
};

export interface AppConfig {
  capabilities?: {
    connection_actions?: {
      action_type: string;
    }[];
  };
  customizations: {
    primary_color: string;
    logo?: string;
    logo_dark_mode?: string;
    animations?: {
      loading?: string;
    };
  };
  default_user_id_format?: string;
  automations?: Automation[];
  user_merge_options?: {
    state?: 'enabled' | 'disabled';
  };
  bottom_sheet?: {
    enabled?: boolean;
  };
  hub?: {
    auth?: {
      remember_sign_in_method?: boolean;
      allow_unverified_users?: boolean;
      primary_sign_up_method?: keyof SignInMethods;
      additional_fields: [
        {
          name: string;
          type: string;
          label: string;
          placeholder?: string;
          options: [
            {
              value: string;
              label: string;
            }
          ];
        }
      ];
      email: {
        from_address: string;
        image: string;
      };
      sign_in_methods?: SignInMethods;
      order?: AuthOrder;
      show_app_icon?: boolean;
      preferred_method?: keyof SignInMethods;
      use_explicit_sign_up_flow?: boolean;
      instant_user?: {
        enabled?: boolean;
        auto_delete?: string;
      };
    };
    profile?: {
      account_information?: {
        methods?: Record<keyof SignInMethods, { enabled?: boolean }>;
      };
      personal_information?: {
        enabled?: boolean;
      };
      preferences?: {
        enabled?: boolean;
      };
      add_sign_in_methods_button?: {
        enabled?: boolean;
      };
      sign_out_button?: {
        enabled?: boolean;
      };
      delete_account_button?: {
        enabled?: boolean;
      };
    };
    customizations?: HubCustomizations;
    custom_scripts?: { type: string; content: string }[];
    custom_styles?: { content: string }[];
    custom_content?: {
      verification_modal?: {
        title?: string;
        subtitle?: string;
      };
      sign_in_modal?: {
        title?: string;
        subtitle?: string;
        sign_in_title?: string;
        sign_up_title?: string;
        sign_in_subtitle?: string;
        sign_up_subtitle?: string;
        sign_in_button?: string;
        sign_up_button?: string;
      };
      no_account_message?: {
        title?: string;
      };
      profile_modal?: {
        title?: string;
      };
    };
    default_redirect_url?: string;
    custom_props?: Record<string, any>;
    legal?: {
      company_name?: string;
      privacy_policy_url?: string;
      terms_conditions_url?: string;
      support_email?: string;
    };
  };
}

export interface AuthOrder {
  default?: { type: 'button' | 'input'; name: keyof SignInMethods; hidden?: boolean }[];
  ios?: { type: 'button' | 'input'; name: keyof SignInMethods; hidden?: boolean }[];
  android?: { type: 'button' | 'input'; name: keyof SignInMethods; hidden?: boolean }[];
}

export const INPUT_SIGN_IN_METHODS: (keyof SignInMethods)[] = ['email', 'phone'];

export const BUTTON_SIGN_IN_METHODS: (keyof SignInMethods)[] = [
  'apple',
  'google',
  'crypto_wallet',
  'passkeys',
  'anonymous',
  'oauth2',
];

export interface SignInMethods {
  email?: {
    enabled: boolean;
  };
  phone?: {
    enabled: boolean;
  };
  apple?: {
    enabled: boolean;
    client_id: string;
  };
  google?: {
    enabled: boolean;
    client_id: string;
    ios_client_id: string;
    scopes: string[];
    one_tap: {
      browser: {
        auto_prompt: boolean;
        delay: number;
      };
    };
  };
  crypto_wallet?: {
    enabled: boolean;
  };
  passkeys?: {
    enabled: boolean;
    domains: string[];
    registration_prompt_frequency: string;
  };
  anonymous?: {
    enabled: boolean;
  };
  oauth2?: {
    enabled: boolean;
  };
}

export enum DarkMode {
  Auto = 'auto',
  Enabled = 'enabled',
  Disabled = 'disabled',
}

export interface HubCustomizations {
  rounded_corners?: boolean;
  primary_color?: string;
  primary_color_dark_mode?: string;
  container_border_radius?: number;
  placement?: 'bottom-left' | 'hidden';
  offset_x?: number;
  offset_y?: number;
  property_overrides?: Record<string, string>;
  dark_mode?: DarkMode;
  visual_swoops?: boolean;
  blur_background?: boolean;
  hide_verification_icons?: boolean;
}

export type TAction = {
  type: ActionType;
  payload?: any;
};

export type Dispatch = (action: TAction) => void;

export enum ActionType {
  SET_CONTAINER_VISIBLE = 'SET_CONTAINER_VISIBLE',
  CHANGE_ROUTE = 'CHANGE_ROUTE',
  SET_POPUP_ROUTE = 'SET_POPUP_ROUTE',
  SET_HUB_STEP = 'SET_HUB_STEP',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOAD_USER = 'LOAD_USER',
  SET_IS_INITIALIZING = 'SET_IS_INITIALIZING',
  SET_IS_LOADING_USER_DATA = 'SET_IS_LOADING_USER_DATA',
  REFRESH_TOKEN = 'REFRESH_TOKEN',
  SIGN_OUT = 'SIGN_OUT',
  SET_USER_DATA_FIELD = 'SET_USER_DATA_FIELD',
  SET_USER_DATA = 'SET_USER_DATA',
  SET_USER_META = 'SET_USER_META',
  SET_INSTANT_USER_IS_INITIALIZING = 'SET_INSTANT_USER_IS_INITIALIZING',
  SET_REFRESH_USER_DATA = 'SET_REFRESH_USER_DATA',
  LOAD_STATE = 'LOAD_STATE',
  SYNC_STATE = 'SYNC_STATE',
  SET_SECTION = 'SET_SECTION',
  SET_IS_SAVING_USER_DATA = 'SET_IS_SAVING_USER_DATA',
  SET_APP_CONFIG = 'SET_APP_CONFIG',
  SET_COLOR_SCHEME = 'SET_COLOR_SCHEME',
  SET_SIGN_IN_METHOD = 'SET_SIGN_IN_METHOD',
  SET_USER_PASSKEYS = 'SET_USER_PASSKEYS',
  SET_IS_WAITING_FOR_POST_AUTHENTICATION_API = 'SET_IS_WAITING_FOR_POST_AUTHENTICATION_API',
  SET_IS_ACCEPTING_GROUP_INVITE = 'SET_IS_ACCEPTING_GROUP_INVITE',
  SET_IS_POST_SIGN_IN_REQUIREMENTS_DONE = 'SET_IS_POST_SIGN_IN_REQUIREMENTS_DONE',
  SET_AUTOMATION_QUEUE = 'SET_AUTOMATION_QUEUE',
  SET_USE_MODAL = 'SET_USE_MODAL',
  SET_ANDROID_ACTIVE_ACCOUNTS = 'SET_ANDROID_ACTIVE_ACCOUNTS',
  RESET_NAV_STATE = 'RESET_NAV_STATE',
  SET_LOCAL_SETTINGS_DARK_MODE = 'SET_LOCAL_SETTINGS_DARK_MODE',
}

export const GlobalContext = createContext<{ state: GlobalState; dispatch: Dispatch } | undefined>(undefined);

export function useGlobalContext() {
  const context = useContext(GlobalContext);

  if (context === undefined) {
    throw new Error('useGlobalContext must be used within a GlobalContext Provider');
  }

  return context;
}
