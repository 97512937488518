// Polyfills
import '@ungap/global-this';
import flatMap from 'array.prototype.flatmap';

// Main imports
import 'preact/devtools';

import { h, render } from 'preact';
import { Context } from './Context';
import Container from './Container';
import { TQuestion } from './DataCollector/Question';
import { logger } from './utils/log';
import { initStorage } from './storage';
import { events, EventType } from './events';

import { init as initI18n, I18nextProvider } from './utils/i18n';
import { ExternalApiSpec, PostAuthApiSpec, PostSignOutApiSpec, PostUserDataUpdateApiSpec } from './ExternalApi';
import Customizations from './Customizations';
import CustomScripts from './CustomScripts';
import CustomStyles from './CustomStyles';
import Automations from './Automations';
import { GlobalState } from './hooks/use-global-context';
import ThirdPartyScripts from './ThirdPartyScripts';

import packageJson from '../../package.json';
import GoogleOneTap from './GoogleOneTap';
import postRobot from 'post-robot';
import DurationProvider from './Context/DurationContext/DurationContext';
import LocationProvider from './Context/LocationContext/LocationContext';

//Apply flatMap polyfill if it does not exist
if (!Array.prototype.flatMap) {
  Object.defineProperty(Array.prototype, 'flatMap', {
    value: flatMap.getPolyfill(),
  });
}

declare global {
  interface Window {
    _rphConfig: string[][];
    rph: object;
    rownd: ExternalApiSpec;
    turnstile: any;
    grecaptcha: any;
    onloadRowndTurnstileCallback: () => void;
    onloadRowndRecaptchaCallback: () => void;
  }
}

interface ICfgFns {
  [name: string]: (...args: any[]) => any;
}

export interface IConfig {
  baseUrl: string;
  apiUrl: string;
  pdcBaseUrl: string;
  appKey: string;
  onLoaded: Array<() => void>;
  questions: TQuestion[];
  postLoginUrl: null | string;
  postRegistrationUrl: null | string;
  rootOrigin: null | string;
  stateListener: null | (({ state, api }: { state: GlobalState; api: ExternalApiSpec }) => void);
  stateListeners: null | (({ state, api }: { state: GlobalState; api: ExternalApiSpec }) => void)[];
  stateStringListener: null | ((state: string) => void);
  locationHash: null | string;
  postAuthenticationApi: null | PostAuthApiSpec;
  postSignInCallback: null | (() => Promise<void>);
  postSignOutApi: null | PostSignOutApiSpec;
  postSignOutCallback: null | (() => void);
  postUserDataUpdateApi: null | PostUserDataUpdateApiSpec;
  displayContext: 'browser' | 'mobile_app';
  appleIdCallbackUrl: string | null;
  googleIdCallbackUrl: string | null;
  oauth2AuthorizeUrl: string | null;
  bottomSheet: boolean;
}

const config: IConfig = {
  baseUrl: 'https://hub.rownd.io',
  apiUrl: 'https://api.rownd.io',
  pdcBaseUrl: 'https://mydata.rownd.io',
  appleIdCallbackUrl: null,
  googleIdCallbackUrl: null,
  oauth2AuthorizeUrl: null,
  appKey: '',
  onLoaded: [],
  questions: [],
  postLoginUrl: null,
  postRegistrationUrl: null,
  rootOrigin: null,
  stateListener: null,
  stateListeners: [],
  stateStringListener: null,
  locationHash: null,
  postAuthenticationApi: null,
  postSignInCallback: null,
  postSignOutApi: null,
  postSignOutCallback: null,
  postUserDataUpdateApi: null,
  displayContext: 'browser',
  bottomSheet: window.localStorage?.getItem('rph_bottom_sheet') !== 'false',
};

function addStateListener(listener: ({ state, api }: { state: GlobalState; api: ExternalApiSpec }) => void) {

  if (!config.stateListeners) {
    config.stateListeners = [];
  }

  if (!config.stateListeners.includes(listener)) {
    config.stateListeners.push(listener);
  }
}

const cfgFns: ICfgFns = {
  setBaseUrl(url: string) {
    const baseUrlOverride = window.localStorage.getItem('rph_base_url_override');
    config.baseUrl = baseUrlOverride || url || config.baseUrl;
  },

  setPdcBaseUrl(url: string) {
    config.pdcBaseUrl = url || config.pdcBaseUrl;
  },

  setAppleIdCallbackUrl(url: string) {
    config.appleIdCallbackUrl = url || config.appleIdCallbackUrl;
  },

  setGoogleIdCallbackUrl(url: string) {
    config.googleIdCallbackUrl = url || config.googleIdCallbackUrl;
  },

  setOauth2AuthorizekUrl(url: string) {
    config.oauth2AuthorizeUrl = url || config.oauth2AuthorizeUrl;
  },

  setAppKey(key: string) {
    config.appKey = key;
  },

  setApiUrl(url: string) {
    config.apiUrl = url || config.apiUrl;
  },

  onLoaded(cb: () => void) {
    config.onLoaded.push(cb);
  },

  setQuestion(question: TQuestion) {
    config.questions.push(question);
  },

  setPostLoginRedirect(url: string) {
    config.postLoginUrl = url;
  },

  setPostRegistrationRedirect(url: string) {
    config.postRegistrationUrl = url;
  },

  setRootOrigin(origin: string) {
    config.rootOrigin = origin;
  },

  setStateListener: addStateListener,

  addStateListener,

  setStateStringListener(listener: (state: string) => void) {
    config.stateStringListener = listener;
  },

  setLocationHash(hash: string) {
    config.locationHash = hash;
  },

  setPostAuthenticationApi(api: PostAuthApiSpec) {
    config.postAuthenticationApi = api;
  },

  setPostSignInCallback(cb: () => Promise<void>) {
    config.postSignInCallback = cb;
  },

  setPostSignOutApi(api: PostSignOutApiSpec) {
    config.postSignOutApi = api;
  },

  setPostSignOutCallback(cb: () => void) {
    config.postSignOutCallback = cb;
  },

  setPostUserDataUpdateApi(api: PostUserDataUpdateApiSpec) {
    config.postUserDataUpdateApi = api;
  },

  setDisplayContext(displayContext: 'browser' | 'mobile_app') {
    config.displayContext = displayContext;
  },

  setBottomSheet(e: boolean) {
    config.bottomSheet = e;
  },

  setLogLevel(level: string) {
    window.localStorage.setItem('rph_log_level', level);
  },
};

const STYLESHEET_ID = 'rph_stylesheet';

function loadStyle(id: string, path: string, base = config.baseUrl) {
  const head = document.getElementsByTagName('head')[0];
  if (!document.getElementById(STYLESHEET_ID)) {
    const link = document.createElement('link');
    link.id = id;
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.href = `${base}/${path}`;
    link.media = 'all';
    head.appendChild(link);
  }
}

async function renderRph() {
  loadStyle(
    'rph_stylesheet',
    `static/styles/rph.css?v=${process?.env?.CF_PAGES_COMMIT_SHA?.substring(0, 7) || packageJson.version}`
  );

  await initI18n({ baseUrl: config.baseUrl });

  // Inject a temporary node that will be replaced with the real RPH node
  const tmpNode = document.createElement('div');
  tmpNode.id = 'rph_tmp_node';
  document.body.appendChild(tmpNode);

  const { default: i18n } = await import('i18next');

  render(
    <LocationProvider>
      <I18nextProvider i18n={i18n}>
        <Context config={config}>
          <DurationProvider>
            <Automations />
            <Customizations />
            <CustomScripts />
            <CustomStyles />
            <ThirdPartyScripts />
            <GoogleOneTap />
            <Container />
          </DurationProvider>
        </Context>
      </I18nextProvider>
    </LocationProvider>,
    document.body,
    tmpNode,
  );
}

function processConfigItem(setting: string[]) {
  const localSetting = [...setting];
  const fnName: string = localSetting[0];
  localSetting.splice(0, 1);

  if (!cfgFns[fnName]) {
    logger.warn(`init fn '${fnName}' not found`);
    return;
  }

  cfgFns[fnName].apply(null, localSetting);
}

(function init() {
  if (!window._rphConfig || !Array.isArray(window._rphConfig)) {
    return logger.error('Failed to initialize. _rphConfig configuration not found or was invalid.');
  }

  // Set proxy to handle new items that may be added "late" (e.g., during init).
  // Mainly for handling late `onLoaded` callbacks.
  if ('Proxy' in window) {
    window._rphConfig = new Proxy(window._rphConfig, {
      set(target, property, value, receiver) {
        if (property !== 'length') {
          processConfigItem(value);
        }

        return Reflect.set(target, property, value, receiver);
      },
    });
  }

  const _rphConfig: string[][] = window._rphConfig;

  _rphConfig.forEach(processConfigItem);

  // Pick up the window's location hash if it wasn't passed to us in the config
  if (!config.locationHash && window.location.hash) {
    cfgFns.setLocationHash(window.location.hash);
  }

  events.addEventListener(
    EventType.STORAGE_READY,
    () => {
      // Don't render the full Hub UI when we are running in root_storage_iframe mode
      const rowndMode = new URLSearchParams(window.location.search).get('rownd_mode');
      if (rowndMode === 'root_storage_iframe') {
        // Listen to root local storage changes
        const onLocalStorageChange = (event: any) => {
          if (event.storageArea === localStorage) {
            postRobot.send(window.parent, 'storage.rootSync', localStorage);
          }
        };
        window.addEventListener('storage', onLocalStorageChange);
        return;
      }

      renderRph();
    },
    { once: true },
  );

  initStorage(config);
})();
