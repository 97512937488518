import preact, { h, Fragment } from 'preact';
import { useState, useEffect, useCallback, useMemo, useRef, useReducer, useLayoutEffect } from 'preact/hooks';
import { useTranslation, Trans } from 'preact-i18next';
import { differenceInMinutes } from 'date-fns';
import classnames from 'classnames';
import jwt_decode from 'jwt-decode';
import type { Web3Provider, Provider } from '@ethersproject/providers';
import { useApi, useInterval, usePasskeys, useRoute, useNear } from './hooks';
import { logger } from './utils/log';
import {
  useGlobalContext,
  ActionType,
  SignInMethods,
  INPUT_SIGN_IN_METHODS,
  BUTTON_SIGN_IN_METHODS,
} from './hooks/use-global-context';
import type { PhoneResult } from 'phone';
import { MessageType, sendMessageToApp } from './utils/mobile-app';
import BackControl from './BackControl';
import SwoopsAnimation from './Components/Common/Swoops/SwoopsAnimation';
import useGoogleSignIn from './hooks/use-google-signin';
import useSessionStorage from './hooks/use-session-storage';
import { FeatureFlagTypes, isFeatureEnabled } from './utils/storage';
import Button from './Components/Common/Button/Button';
import LoginButtons from './Components/Login/LoginButtons/LoginButtons';
import LoginInput from './Components/Login/LoginInput/LoginInput';
import * as Selectors from './utils/Selectors';
import { RequestSignInIntent } from './ExternalApi';
import { UserType } from './DefaultContext';
import { getUserAgentType, UserAgentType, validEmail } from './utils';
import { SignInError } from './Components/Login/Error/Error';
import { AuthenticationResponseJSON } from '@simplewebauthn/typescript-types';
import { SignInSuccess } from './Components/Login/Success/Success';
import useSuccessSignIn from './hooks/use-success-signin';
import { SignInLoading } from './Components/Login/Loading/Loading';
import { isInIframe } from './utils/iframe-detect';
import CloseControl from './CloseControl';
import {
  ChooseWalletProvider,
  ProviderInfo,
  ProviderType,
} from './Components/Login/ChooseWalletProvider/ChooseWalletProvider';
import useAppleSignIn from './hooks/use-apple-signin';
import useSignIn, { SignInMethodTypes } from './hooks/use-sign-in';
import useHandleClose from './hooks/use-handle-close';
import UnauthorizedWebOrigins from './Components/Common/UnauthorizedWebOrigins/UnauthorizedWebOrigins';
import useMetrics, { MetricEvent } from './hooks/use-metrics';
import { ROWND_LINKS, RowndHomePage } from './utils/links';
import useSafeLocation from './hooks/use-window-location';
import useUserApi from './hooks/use-user-api';
import { EventType, events } from './events';
import useBottomSheet from './hooks/use-bottom-sheet';
import { useInstantUser } from './hooks/use-instant-user';
import { TokenClaims } from './utils/token';
import useSignInMethods from './hooks/use-sign-in-methods';
import { RowndApiError } from './hooks/use-api';

export enum LoginStep {
  INIT = 'init',
  WAITING = 'waiting',
  COMPLETING = 'completing',
  SUCCESS = 'success',
  FAILURE = 'failure',
  NO_ACCOUNT = 'no_account',
  EXISTING_ACCOUNT = 'existing_account',
  ERROR = 'error',
  CHOOSE_WALLET_PROVIDER = 'choose_wallet_provider',
  WAITING_FOR_WALLET_PROVIDER = 'waiting_for_wallet_provider',
  VERIFY = 'verify',
  CONNECT_ACCOUNT = 'connect_account',
}

export type LoginInitBody = {
  fingerprint: {
    status: 'valid' | 'invalid';
  };
  challenge_id: string;
  message: string;
  auth_tokens?: {
    access_token: string;
    refresh_token: string;
  };
  registration_status: string;
  init_data?: Record<string, any>;
  user_type?: string;
};

export type SignInState =
  | {
      step:
        | LoginStep.INIT
        | LoginStep.WAITING
        | LoginStep.COMPLETING
        | LoginStep.FAILURE
        | LoginStep.NO_ACCOUNT
        | LoginStep.EXISTING_ACCOUNT
        | LoginStep.EXISTING_ACCOUNT
        | LoginStep.ERROR
        | LoginStep.CHOOSE_WALLET_PROVIDER
        | LoginStep.WAITING_FOR_WALLET_PROVIDER
        | LoginStep.VERIFY
        | LoginStep.CONNECT_ACCOUNT;
    }
  | {
      step: LoginStep.SUCCESS;
      waitingForPostSignInApi?: boolean;
      postRegistrationUrl?: string;
    };

type LoginTokenSuccessBody = {
  access_token: string;
  refresh_token: string;
  sign_in_method: string;
};

export type LoginSuccessBody = {
  access_token: string;
  refresh_token: string;
  app_user_id: string;
  app_id: string;
  status: string;
  user_type?: string;
  intent?: string;
};

export enum LoginVerificationStatus {
  PENDING = 'pending',
  EXPIRED = 'expired',
  VERIFIED = 'verified',
}

export const loginTypeToFieldMap: Record<keyof SignInMethods, string | null> = {
  email: 'email',
  phone: 'phone_number',
  apple: 'apple_id',
  google: 'google_id',
  crypto_wallet: 'crypto_wallet_address',
  passkeys: null,
  anonymous: 'anonymous_id',
  oauth2: 'oauth2',
};

interface LoginProps {
  onClose?: () => void;
}

export default function Login({ onClose }: LoginProps) {
  const { t } = useTranslation();
  const { navTo, setPopupRoute } = useRoute();
  const { authenticate, probeOneTap } = useGoogleSignIn();
  const {
    authenticate: passkeysAuthenticate,
    eagerlyRequestAuthenticationChallenge: passkeysEagerlyRequestAuthenticationChallenge,
    startAuthentication: passkeysStartAuthentication,
  } = usePasskeys();
  const successSignIn = useSuccessSignIn();
  const near = useNear();
  const handleClose = useHandleClose();
  const { collectMetric } = useMetrics();
  const safeLocation = useSafeLocation();
  const { saveUserData } = useUserApi();

  const { state, dispatch } = useGlobalContext();
  const { isBottomSheetEnabled } = useBottomSheet();
  const { instantUserId } = useInstantUser();
  const { allowedIdentifiers, signInMethodsEnabled } = useSignInMethods();
  const { config, nav, app, user, computed_color_mode } = state;

  let decodedAccessToken: any;
  if (state.auth.access_token) {
    try {
      decodedAccessToken = jwt_decode(state.auth.access_token);
    } catch (err) {
      logger.warn('Failed to decode access token while rendering the sign-in component', err);
    }
  }

  const [isMobileApp] = useState(config?.displayContext === 'mobile_app');
  const [fieldError, setFieldError] = useState<string | null>(null);
  const initialStep = useMemo(() => {
    if (nav.options?.login_step) {
      return nav.options.login_step as LoginStep;
    }
    return state.auth.access_token && !instantUserId && decodedAccessToken?.[TokenClaims.IsVerifiedUser] !== false
      ? LoginStep.SUCCESS
      : LoginStep.INIT;
  }, [decodedAccessToken, instantUserId, nav.options.login_step, state.auth.access_token]);

  const determineInitialIntent = useCallback((): RequestSignInIntent | undefined => {
    const signInUpFlow = app.config?.hub?.auth?.use_explicit_sign_up_flow;
    if (
      signInUpFlow !== true &&
      (nav.options?.intent === RequestSignInIntent.SignIn || nav.options?.intent === RequestSignInIntent.SignUp)
    ) {
      logger.warn('App config is not setup for Sign-in/Sign-up');
    }
    if (signInUpFlow === true && nav.options?.intent === RequestSignInIntent.SignIn) {
      return RequestSignInIntent.SignIn;
    }
    if (signInUpFlow === true && nav.options?.intent === RequestSignInIntent.SignUp) {
      return RequestSignInIntent.SignUp;
    }
    return undefined;
  }, [app.config?.hub?.auth?.use_explicit_sign_up_flow, nav.options?.intent]);

  const additionalSignInMethods = useMemo(() => {
    return BUTTON_SIGN_IN_METHODS.some((method) => {
      return app?.config?.hub?.auth?.sign_in_methods?.[method]?.enabled && signInMethodsEnabled[method];
    });
  }, [app?.config?.hub?.auth?.sign_in_methods, signInMethodsEnabled]);

  const isOneMethodEnabled = useMemo((): boolean => {
    const signInMethodsEnabledArray = Object.keys(signInMethodsEnabled) as (keyof SignInMethods)[];
    return signInMethodsEnabledArray?.filter((method) => signInMethodsEnabled[method]).length === 1;
  }, [signInMethodsEnabled]);

  const determineInitialSignIn = useMemo((): {
    selectedMethod: keyof SignInMethods | null;
    isPreviousMethod: boolean;
  } => {
    // nav.options.sign_in_type could be set by the external API when
    // `requestSignIn({ method: <METHOD> })` is invoked.
    if (nav?.options?.sign_in_type) {
      return {
        selectedMethod: nav.options.sign_in_type,
        isPreviousMethod: false,
      };
    }

    const rememberSignInMethod = !(app?.config?.hub?.auth?.remember_sign_in_method === false);
    const intent = determineInitialIntent();
    if (intent === RequestSignInIntent.SignUp || intent === RequestSignInIntent.SignIn || nav.options?.auto_sign_in) {
      return {
        selectedMethod: null,
        isPreviousMethod: false,
      };
    }

    // Set initial Sign in method depending on previous Sign in method
    if (
      rememberSignInMethod &&
      state?.sign_in?.last_sign_in &&
      signInMethodsEnabled[state?.sign_in?.last_sign_in] &&
      state?.sign_in?.last_sign_in_date &&
      !isOneMethodEnabled
    ) {
      const currentDate = +new Date();
      const savedDate = +new Date(state.sign_in.last_sign_in_date);
      if (savedDate + 1000 * 60 * 5 < currentDate) {
        // Check if saved Date is older than 5mins
        return {
          selectedMethod: state.sign_in.last_sign_in,
          isPreviousMethod: true,
        };
      }
    }
    return {
      selectedMethod: null,
      isPreviousMethod: false,
    };
  }, [
    app?.config?.hub?.auth?.remember_sign_in_method,
    determineInitialIntent,
    isOneMethodEnabled,
    nav.options?.auto_sign_in,
    nav.options?.sign_in_type,
    signInMethodsEnabled,
    state.sign_in.last_sign_in,
    state.sign_in.last_sign_in_date,
  ]);

  const sessionStorage = useSessionStorage();
  const [signInState, setSignInState] = useState<SignInState>({ step: initialStep });
  const [error, setError] = useState(nav?.options?.error_message || '');
  const [requestId, setRequestId] = useState<string | null>(nav?.options.request_id || null);
  const [loginPollStart, setLoginPollStart] = useState<number | null>(null);
  const [isTokenSubmitting, setIsTokenSubmitting] = useState(false);
  const [isSubmittingWeb3, setIsSubmittingWeb3] = useState(false);
  const [verifyWalletBtnState, setVerifyWalletBtnState] = useState(false);
  const [loginType, setLoginType] = useState<keyof SignInMethods | null>(nav?.options?.sign_in_type || null);
  const [phoneDetails, setPhoneDetails] = useState<any | null>(null);
  const [isValidUserIdentifier, setIsValidUserIdentifier] = useState(false);
  const [requiresAdditionalFields, setRequiresAdditionalFields] = useState(nav?.options?.init_data);
  const [shouldAlwaysSendVerificationMessage, setShouldAlwaysSendVerificationMessage] = useState(false);
  const [isResendingMessage, setIsResendingMessage] = useState(false);
  const [didResendMessage, setDidResendMessage] = useState(false);
  const [canResendMessage, setCanResendMessage] = useState(false);
  const [prevSteps, setPrevSteps] = useState<LoginStep[]>([]);
  const [userIdentifier, setUserIdentifier] = useState(
    () =>
      nav.options?.identifier ||
      state.user?.data?.email ||
      state.user?.data?.phone_number ||
      state.user?.data?.crypto_wallet_address ||
      ''
  );
  const [shouldAutoSignIn, setShouldAutoSignIn] = useState(!!nav.options?.auto_sign_in && !!userIdentifier);
  const [showAllMethods, setShowAllMethods] = useState(false);
  const [selectedMethod, setSelectedMethod] = useState<keyof SignInMethods | null>(
    determineInitialSignIn.selectedMethod
  );
  const [isPreviousMethod, setIsPreviousMethod] = useState(determineInitialSignIn.isPreviousMethod);
  const [token, setToken] = useState<string | null>(nav.options?.token);
  const [intent, setIntent] = useState<RequestSignInIntent | undefined>(determineInitialIntent());
  const [isExistingUser, setIsExistingUser] = useState(
    nav.options?.user_type === UserType.ExistingUser &&
      nav.options?.login_step === LoginStep.SUCCESS &&
      nav.options?.intent === RequestSignInIntent.SignUp
  );
  const [isSendingMetrics, setIsSendingMetrics] = useState(false);
  const { initSignIn, isSubmitting: isInitSubmitting } = useSignIn();

  const isSubmitting = useMemo(() => isInitSubmitting || isTokenSubmitting, [isInitSubmitting, isTokenSubmitting]);

  const goBack = useCallback(() => {
    const prevStepsCopy = [...prevSteps];
    setSignInState({ step: prevStepsCopy.pop() || LoginStep.INIT });
    setPrevSteps(prevStepsCopy);
  }, [prevSteps]);

  const addlFieldInit = useCallback(
    (state: Record<string, string>) => {
      const addlFields = app?.config?.hub?.auth?.additional_fields;
      const addlInputs = nav?.options?.init_data || nav?.options?.default_values || {};

      const newState: Record<string, string> = {};
      if (addlFields?.length) {
        for (const field of addlFields) {
          if (field?.options) {
            newState[field.name] = addlInputs?.[field.name] || field.options[0].value;
          }
        }
      }

      return {
        ...state,
        ...newState,
      };
    },
    [app?.config?.hub?.auth?.additional_fields, nav?.options?.default_values, nav?.options?.init_data]
  );

  const fieldReducer = useCallback(
    (state: any, action: { type: string; payload?: Record<string, string> }) => {
      switch (action.type) {
        case 'reset':
          return addlFieldInit(state);

        default:
          return {
            ...state,
            ...action.payload,
          };
      }
    },
    [addlFieldInit]
  );

  const [addlFieldValues, addlFieldDispatch] = useReducer(fieldReducer, {});

  const inputRef = useRef<HTMLInputElement | null>(null);

  const { client: api } = useApi();

  function validateEmail(email: string): boolean {
    const isValid = validEmail(email);

    if (!isValid) {
      setFieldError('Invalid email address');
      return false;
    }

    setFieldError(null);
    setLoginType('email');
    return true;
  }

  const phone = useRef<any>(null);

  const getPhoneResult = useCallback(async (phoneNumber: string): Promise<PhoneResult> => {
    if (!phone?.current) {
      const p: any = await import('phone');
      // This is a quirk of bundle splitting
      phone.current = typeof p.default === 'function' ? p.default : p.default.phone;
    }
    return phone.current(phoneNumber);
  }, []);

  const isValidPhone = useCallback(async (): Promise<boolean> => {
    const phoneResult: PhoneResult = await getPhoneResult(userIdentifier);
    if (!phoneResult.isValid) {
      if (loginType === 'phone') {
        setLoginType(null);
      }
      setPhoneDetails(null);
      return false;
    }

    setFieldError(null);
    setLoginType('phone');
    setPhoneDetails(phoneResult);
    setUserIdentifier(phoneResult.phoneNumber);
    return true;
  }, [getPhoneResult, loginType, userIdentifier]);

  const isValidEmail = useCallback((): boolean => {
    const emailAtIdx = userIdentifier?.indexOf('@');
    const emailSuffixIdx = userIdentifier?.substring(emailAtIdx).indexOf('.');
    if (emailAtIdx > 0 && emailSuffixIdx > 0 && userIdentifier?.substring(emailAtIdx + emailSuffixIdx).length >= 3) {
      return validateEmail(userIdentifier);
    }

    return false;
  }, [userIdentifier]);

  const validateInput = useCallback(() => {
    (async () => {
      const validations = [];
      if (allowedIdentifiers.includes('phone')) {
        validations.push(isValidPhone);
      }

      if (allowedIdentifiers.includes('email')) {
        validations.push(isValidEmail);
      }

      let foundAtLeastOneValidIdentifier = false;
      for (const validationFn of validations) {
        if (await validationFn()) {
          try {
            foundAtLeastOneValidIdentifier = true;
            break;
          } catch (e) {
            logger.warn('One sign-in identifier validator failed:', e);
          }
        }
      }

      setIsValidUserIdentifier(foundAtLeastOneValidIdentifier);
    })();
  }, [allowedIdentifiers, isValidEmail, isValidPhone]);

  useEffect(() => {
    if (isMobileApp) {
      // Ensure page is at the top for every login step
      window.scrollTo(0, 0);

      // Disable mobile background touch to dismiss during verification
      sendMessageToApp({
        type: MessageType.CAN_TOUCH_BACKGROUND_TO_DISMISS,
        payload: {
          enable: signInState.step === LoginStep.WAITING || signInState.step === LoginStep.VERIFY ? 'false' : 'true',
        },
      });
    }

    return () => {
      sendMessageToApp({
        type: MessageType.CAN_TOUCH_BACKGROUND_TO_DISMISS,
        payload: {
          enable: 'true',
        },
      });
    };
  }, [signInState.step, isMobileApp]);

  useEffect(() => {
    dispatch({
      type: ActionType.SET_HUB_STEP,
      payload: signInState.step,
    });

    return () => {
      dispatch({
        type: ActionType.SET_HUB_STEP,
        payload: undefined,
      });
    };
  }, [signInState.step, dispatch]);

  // Validate input
  useEffect(validateInput, [validateInput]);

  // Reset state if a signout occurs
  useEffect(() => {
    if (!state.auth.access_token) {
      setSignInState({ step: LoginStep.INIT });
    }
  }, [state.auth.access_token]);

  useEffect(() => {
    if (signInState.step === LoginStep.INIT && !!state.auth.access_token && !isSubmitting && !instantUserId) {
      setSignInState({ step: LoginStep.SUCCESS });
    }
  }, [state.auth.access_token, isSubmitting, signInState.step, instantUserId]);

  // Ensure cursor remains at end of login input if the input type changes
  useEffect(() => {
    const input = inputRef.current;
    if (input) {
      const oldType: string = input.getAttribute('type')!;
      input.setAttribute('type', 'text');
      const cursorLoc = input.selectionStart || input.value?.length;
      input.setSelectionRange(cursorLoc, cursorLoc);
      input.setAttribute('type', oldType);
    }
  }, [inputRef, loginType]);

  // This initializes any additional fields once we have an app config
  // Without this, we may not properly set the initial/default values
  useEffect(() => {
    addlFieldDispatch({
      type: 'reset',
    });
  }, [app?.config]);

  // Update sign-in method from mobile apps if changed
  useEffect(() => {
    setLoginType(nav?.options?.sign_in_type || 'email');
  }, [nav?.options?.sign_in_type]);

  // Update user status if changed externally
  useEffect(() => {
    setIsExistingUser(
      nav.options?.user_type === UserType.ExistingUser &&
        nav.options?.login_step === LoginStep.SUCCESS &&
        nav.options?.intent === RequestSignInIntent.SignUp
    );
  }, [nav.options?.intent, nav.options?.login_step, nav.options?.user_type]);

  const pollLoginStatus = useCallback(async () => {
    try {
      const resp: LoginSuccessBody = await api
        .post(`hub/auth/challenge_status`, {
          headers: {
            'x-rownd-app-key': config?.appKey,
          },
          json: {
            challenge_id: requestId,
            [loginType || 'email']: userIdentifier,
          },
        })
        .json();

      let err: any;
      switch (resp.status) {
        case 'pending':
          setSignInState({ step: LoginStep.WAITING });
          err = new Error('Login challenge is still pending');
          err.code = LoginVerificationStatus.PENDING;
          throw err;

        case 'expired':
          err = new Error('Login challenge is expired');
          err.code = LoginVerificationStatus.EXPIRED;
          sendMessageToApp({
            type: MessageType.AUTH_CHALLENGE_CLEARED,
          });
          throw err;

        case 'verified':
          events.dispatch(EventType.VERIFICATION_COMPLETED, {
            method: isValidEmail() ? 'email' : 'phone',
          });
          sendMessageToApp({
            type: MessageType.AUTH_CHALLENGE_CLEARED,
          });
          break;

        default:
          err = new Error('Unknown login challenge status');
          throw err;
      }

      if (resp?.user_type === UserType.ExistingUser && resp?.intent === RequestSignInIntent.SignUp) {
        setIsExistingUser(true);
      }

      dispatch({
        type: ActionType.SET_SIGN_IN_METHOD,
        payload: { last_sign_in: isValidEmail() ? 'email' : 'phone' },
      });

      events.dispatch(EventType.SIGN_IN_COMPLETED, {
        method: isValidEmail() ? 'email' : 'phone',
        user_type: resp?.user_type,
      });

      sendMessageToApp({
        type: MessageType.AUTH_CHALLENGE_CLEARED,
      });

      dispatch({
        type: ActionType.LOGIN_SUCCESS,
        payload: resp,
      });

      setSignInState({ step: LoginStep.SUCCESS });

      // TODO: make it easier for someone to find this tab by flashing the title, etc
      window.focus();
    } catch (err: any) {
      logger.log('login poll error', err);

      // If network error, try again up to 1 minute, else fail
      if (!err.code && differenceInMinutes(Date.now(), loginPollStart!) > 0) {
        setSignInState({ step: LoginStep.ERROR });
        setError(t('Network error, please try again later.'));
        sendMessageToApp({
          type: MessageType.AUTH_CHALLENGE_CLEARED,
        });
        return;
      }

      // If request expires, then fail (assume > 6 mins is a failure/expiration)
      if ((err.status || err.code) && differenceInMinutes(Date.now(), loginPollStart!) > 6) {
        setSignInState({ step: LoginStep.ERROR });
        setError(t('The sign in request expired.'));
        sendMessageToApp({
          type: MessageType.AUTH_CHALLENGE_CLEARED,
        });
        return;
      }

      if (err.status && err.status >= 400) {
        setSignInState({ step: LoginStep.FAILURE });
        setError(t('Sign in unsuccessful.'));
        sendMessageToApp({
          type: MessageType.AUTH_CHALLENGE_CLEARED,
        });
        return;
      }
    }
  }, [api, config?.appKey, dispatch, loginPollStart, loginType, requestId, t, userIdentifier, isValidEmail]);

  useEffect(() => {
    if (requestId && signInState.step === LoginStep.WAITING) {
      setLoginPollStart(Date.now());
      setTimeout(() => setCanResendMessage(true), 10000);
    }
  }, [requestId, signInState.step]);

  useEffect(() => {
    if (loginType && nav?.options.login_step === LoginStep.COMPLETING && nav?.options.request_id && nav?.options.identifier) {
      setLoginPollStart(Date.now());
      pollLoginStatus();
    }
  }, [loginType, nav?.options.login_step, nav?.options.request_id, nav?.options.identifier]);

  // Polling when a login flow is in progress
  useInterval(
    pollLoginStatus,
    signInState.step === LoginStep.WAITING && loginType && ['email', 'phone'].includes(loginType) ? 4000 : null
  );

  // login polling manager
  useEffect(() => {
    if (signInState.step === LoginStep.SUCCESS) {
      successSignIn({ setSignInState, postRegistrationUrl: signInState.postRegistrationUrl });
    }
  }, [signInState.step]);

  // Force state changes when re-mounting based on nav
  useEffect(() => {
    setShouldAutoSignIn(!!nav.options?.auto_sign_in && !!userIdentifier);
    nav.options?.login_step && setSignInState({ step: nav.options.login_step });
  }, [nav?.options]);

  // Focus cursor on this input initially
  useLayoutEffect(() => {
    // Give layout time to settle
    setTimeout(() => {
      if (inputRef.current && !isMobileApp) {
        inputRef.current.focus();
      }
    }, 100);
  }, []);

  useEffect(() => {
    //If a token exists in nav.options, use the decoded email as the userIdentifier
    const token: string | undefined = nav.options?.token;
    if (!token) return;

    const decodedToken: any = jwt_decode(token);
    const email: string | undefined = decodedToken?.email;
    if (email) setUserIdentifier(email);
  }, []);

  const initTokenLogin = useCallback(async () => {
    if (!token) return;

    setIsTokenSubmitting(true);
    try {
      const resp: LoginTokenSuccessBody = await api
        .post('hub/auth/token', {
          json: {
            id_token: token,
            app_id: state.app.id,
            instant_user_id: instantUserId,
          },
        })
        .json();

      if (resp.sign_in_method) {
        dispatch({
          type: ActionType.SET_SIGN_IN_METHOD,
          payload: { last_sign_in: resp.sign_in_method },
        });
      }

      dispatch({
        type: ActionType.LOGIN_SUCCESS,
        payload: {
          access_token: resp.access_token,
          refresh_token: resp.refresh_token,
          app_id: app.id,
        },
      });

      if (nav.options?.user_data) {
        saveUserData(nav.options?.user_data);
      }

      setSignInState({ step: LoginStep.SUCCESS });
    } catch (err: any) {
      setError(t('Sign in unsuccessful. Please try again'));
      setSignInState({ step: LoginStep.ERROR });
    } finally {
      setUserIdentifier('');
      setIsTokenSubmitting(false);
    }
  }, [api, app.id, dispatch, instantUserId, nav.options?.user_data, saveUserData, state.app.id, t, token]);

  const initLogin = useCallback(
    async (evt?: Event, disableIntent?: boolean) => {
      if (evt) {
        evt.preventDefault();
        evt.stopPropagation();
      }
      if ((signInState.step === LoginStep.WAITING && !isResendingMessage) || didResendMessage) {
        return;
      }

      initSignIn({
        setError,
        setSignInState,
        intent,
        disableIntent,
        userIdentifier,
        loginType,
        fieldError,
        setFieldError,
        addlFieldValues,
        addlFieldInit,
        shouldAlwaysSendVerificationMessage,
        setShouldAlwaysSendVerificationMessage,
        setIsExistingUser,
        setRequestId,
        requiresAdditionalFields,
        setRequiresAdditionalFields,
      });
    },
    [
      signInState.step,
      isResendingMessage,
      didResendMessage,
      initSignIn,
      intent,
      userIdentifier,
      loginType,
      fieldError,
      addlFieldValues,
      addlFieldInit,
      shouldAlwaysSendVerificationMessage,
      requiresAdditionalFields,
    ]
  );

  useEffect(() => {
    if (signInState.step === LoginStep.WAITING) {
      return;
    }

    if (shouldAutoSignIn && userIdentifier) {
      validateInput();

      if (isValidUserIdentifier) {
        initLogin();
        setShouldAutoSignIn(false);
      }
    }
  }, [
    userIdentifier,
    initLogin,
    validateInput,
    isValidUserIdentifier,
    signInState.step,
    shouldAutoSignIn,
    state.auth.is_verified_user,
  ]);

  useEffect(() => {
    if (isResendingMessage) {
      initLogin();
      setDidResendMessage(true);
      setTimeout(() => {
        setIsResendingMessage(false);
        setTimeout(() => setDidResendMessage(false), 10000);
      }, 2500);
    }
  }, [isResendingMessage, initLogin]);

  const handleAddlFieldChange = (evt: Event) => {
    const target = evt.target as HTMLInputElement;
    const { value, name } = target;

    addlFieldDispatch({
      type: 'default',
      payload: {
        [name]: value,
      },
    });
  };

  const resendLinkClick = () => {
    if (isResendingMessage || didResendMessage) return null;
    setShouldAlwaysSendVerificationMessage(true);
    setIsResendingMessage(true);
  };

  const cancelVerify = () => {
    dispatch({
      type: ActionType.CHANGE_ROUTE,
      payload: {
        route: `/account/manage`,
        opts: { use_modal: state.use_modal },
      },
    });

    setTimeout(() => {
      setSignInState({ step: LoginStep.INIT });
    }, 250);
  };

  const apple = useAppleSignIn();

  const appleIdSignIn = useCallback(
    ({
      evt,
      opts,
      newIntent,
    }: {
      evt?: Event;
      opts?: {
        purpose?: 'authentication' | 'connect_account';
      };
      newIntent?: RequestSignInIntent;
    }) => {
      evt?.preventDefault();
      evt?.stopPropagation();

      events.dispatch(EventType.SIGN_IN_STARTED, {
        method: SignInMethodTypes.APPLE,
      });

      if (isMobileApp && window?.webkit?.messageHandlers?.rowndIosSDK) {
        return sendMessageToApp({
          type: MessageType.TRIGGER_SIGN_IN_WITH_APPLE,
          payload: {
            intent: newIntent || intent,
            ...(nav.options?.group_to_join && { group_to_join: nav.options?.group_to_join }),
          },
        });
      }
      apple.authenticate({
        purpose: opts?.purpose,
        intent: newIntent || intent,
        groupToJoin: nav.options?.group_to_join,
      });
    },
    [apple, intent, isMobileApp, nav.options?.group_to_join]
  );

  const passkeySignIn = useCallback(async () => {
    setSignInState({ step: LoginStep.WAITING });
    setLoginType('passkeys');

    if (isMobileApp) {
      return sendMessageToApp({
        type: MessageType.TRIGGER_SIGN_IN_WITH_PASSKEY,
        payload: {
          ...(nav.options?.group_to_join && { group_to_join: nav.options?.group_to_join }),
          ...(intent && { intent }),
        },
      });
    }

    try {
      await passkeysAuthenticate({ groupToJoin: nav.options?.group_to_join });
      setSignInState({ step: LoginStep.SUCCESS });
    } catch (err) {
      setSignInState({ step: LoginStep.ERROR });
      setError((err as Error).message || 'Unable to verify your passkey.');
      passkeysEagerlyRequestAuthenticationChallenge();
    }
  }, [isMobileApp, intent, nav.options?.group_to_join, passkeysAuthenticate, passkeysEagerlyRequestAuthenticationChallenge]);

  const hasWalletProvider = () => {
    return window.ethereum !== 'undefined' && window.ethereum;
  };

  const initWeb3 = useCallback(
    async (evt: Event, providerToUse: Provider) => {
      if (!hasWalletProvider()) {
        return;
      }

      let ethers: any;
      try {
        ethers = await import('@ethersproject/providers');
      } catch (err) {
        return logger.error(
          "Web3 authentication is only supported with ESM. Please set your <script> type to 'module' and src to 'https://hub.rownd.io/static/scripts/rph.mjs'"
        );
      }

      let selectedAccount;
      if (signInState.step === LoginStep.WAITING) {
        return;
      }

      setIsSubmittingWeb3(true);

      if (evt) {
        evt.preventDefault();
        evt.stopPropagation();
      }

      setVerifyWalletBtnState(true);

      events.dispatch(EventType.SIGN_IN_STARTED, {
        method: SignInMethodTypes.CRYPTO_WALLET,
      });

      try {
        // A Web3Provider wraps a standard Web3 provider, which is
        // what MetaMask injects as window.ethereum into each page.
        const provider: Web3Provider = new ethers.Web3Provider(providerToUse);

        // MetaMask requires requesting permission to connect users accounts
        // await provider.send('eth_requestAccounts', []);
        try {
          const accounts = await provider.send('eth_requestAccounts', []);
          selectedAccount = accounts[0];
          logger.debug('Selected account is ', { selectedAccount });
        } catch (err) {
          logger.error('eth_requestAccounts failed:', err);
          return;
        }

        // Fetch new account when it's changed
        // TODO: Do something like this to handle switching Rownd accounts when a user switches
        //       accounts in MM or CBW.
        // window.ethereum.on('accountsChanged', (accounts: any) => {
        //     selectedAccount = accounts[0];
        //     logger.debug('Selected account changed', { selectedAccount });
        // });

        const signer = provider.getSigner();
        const chainId = await signer.getChainId();
        logger.debug('The network is: ', chainId);

        /**
         * Switch Newtwork if the the current network doesn't match
         * the specified allowed.
         * It doesn't modify the actual signed message.
         * The id of the chain 0x1 is referring to the Eth mainnet.
         * Change this if you want to force the user to be on a specific Chain.
         */
        /**
                const targetNetworkId = '0x1';
                const switchNetwork = async () => {
                await window.ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: targetNetworkId }],
                });
                };
                // Check that the Network = targetNetworkId.
                if (chainId == 3 || chainId == 4) {
                    await switchNetwork();
                } 
             */

        // Get the public key address of the current user.
        const crypto_wallet_address = await signer.getAddress();

        // Message parameter that will be signed.
        // TODO: For extra auth, apply a nonce.
        const msgParams = 'rownd:signin';

        // Get the message param, and await the signed message.
        const signature = await signer.signMessage(msgParams);

        setLoginType('crypto_wallet');

        // Construct the user_data object for the request payload.
        const userIdentifierFieldName = loginTypeToFieldMap.crypto_wallet;
        const userData = {
          ...user.data, // existing user data
          ...(userIdentifierFieldName && { [userIdentifierFieldName]: userIdentifier }), // userIdentifier
        };

        const payload = {
          crypto_wallet_address,
          crypto_signed_message: signature,
          return_url: nav?.options?.post_login_redirect || state.config?.postLoginUrl || window.location.href,
          user_data: Object.values(userData).some((f) => f !== null && f !== undefined) ? userData : {}, // Include userData if at least one field is defined
        };

        // Set the user_id to the application's default user id format if it is defined
        if (app.config?.default_user_id_format) {
          (payload as any).user_id = app.config?.default_user_id_format;
        }

        if (requiresAdditionalFields) {
          payload.user_data = {
            ...payload.user_data,
            ...addlFieldValues,
          };
        }

        try {
          setVerifyWalletBtnState(false);

          const resp: LoginInitBody = await api
            .post('hub/auth/init', {
              headers: {
                'x-rownd-app-key': config?.appKey,
              },
              json: payload,
            })
            .json();

          if (resp.auth_tokens) {
            dispatch({
              type: ActionType.SET_SIGN_IN_METHOD,
              payload: { last_sign_in: 'crypto_wallet' },
            });

            events.dispatch(EventType.SIGN_IN_COMPLETED, {
              method: SignInMethodTypes.CRYPTO_WALLET,
              user_type: resp?.user_type,
            });

            dispatch({
              type: ActionType.LOGIN_SUCCESS,
              payload: {
                ...resp.auth_tokens,
                app_id: app.id,
              },
            });

            // Call a post-registration endpoint if configured and this is a first-time sign in
            if (resp.registration_status === 'new_user' && state.config?.postRegistrationUrl) {
              let initData = resp?.init_data && btoa(JSON.stringify(resp.init_data));
              initData = initData
                ? state.config.postRegistrationUrl.includes('?')
                  ? `&rownd_init_data=${initData}`
                  : `?rownd_init_data=${initData}`
                : '';

              safeLocation.assign(state.config.postRegistrationUrl + initData);
            }

            setSignInState({ step: LoginStep.SUCCESS });
            return;
          }
        } catch (err: any) {
          logger.error(err);
          setSignInState({ step: LoginStep.ERROR });
          setError(err.message);
        } finally {
          setIsSubmittingWeb3(false);
        }
      } catch (err) {
        console.error(err);
        setVerifyWalletBtnState(false);
        setIsSubmittingWeb3(false);
      }
    },
    [
      signInState.step,
      user.data,
      userIdentifier,
      nav?.options?.post_login_redirect,
      state.config?.postLoginUrl,
      state.config?.postRegistrationUrl,
      app.config?.default_user_id_format,
      app.id,
      requiresAdditionalFields,
      addlFieldValues,
      api,
      config?.appKey,
      dispatch,
      safeLocation,
    ]
  );

  const { name } = app;
  const primaryColor = Selectors.primaryColor(state);
  const visualSwoops =
    typeof app?.config?.hub?.customizations?.visual_swoops === 'boolean'
      ? app?.config?.hub.customizations?.visual_swoops
      : true;
  const verificationModalTitle = app?.config?.hub?.custom_content?.verification_modal?.title;
  const companyName = app?.config?.hub?.legal?.company_name || name; // Default to app name
  const privacyPolicy = app?.config?.hub?.legal?.privacy_policy_url || '';
  const termsConditions = app?.config?.hub?.legal?.terms_conditions_url || '';
  const supportEmail = app?.config?.hub?.legal?.support_email || '';
  const order = app?.config?.hub?.auth?.order;
  const orderType = window?.webkit?.messageHandlers?.rowndIosSDK
    ? 'ios'
    : window?.rowndAndroidSDK
    ? 'android'
    : 'default';
  const orderArray = order?.[orderType] || order?.default || undefined;
  const signInModal = app?.config?.hub?.custom_content?.sign_in_modal;
  const signInModalTitle = nav.options?.title || signInModal?.title;
  const noAccountMessage = app?.config?.hub?.custom_content?.no_account_message;
  const hideVerificationIcons = !!app?.config?.hub?.customizations?.hide_verification_icons;
  const appIcon = Selectors.appIcon(state);
  const allowUnverifiedUsers = app?.config?.hub?.auth?.allow_unverified_users === true;

  const primarySignUpMethod = useMemo(() => {
    if (!app?.config?.hub?.auth?.use_explicit_sign_up_flow || !orderArray?.length) {
      return undefined;
    }

    const filteredAuthOrderArray = orderArray.filter((item) => !item.hidden);

    return filteredAuthOrderArray.length === 1 ? filteredAuthOrderArray[0].name : undefined;
  }, [app?.config?.hub?.auth?.use_explicit_sign_up_flow, orderArray]);

  const googleSignIn = useCallback(
    async ({
      evt,
      opts = { purpose: 'authentication' },
      newIntent,
    }: {
      evt?: Event;
      opts?: { purpose: 'authentication' | 'connect_account' };
      newIntent?: RequestSignInIntent;
    }) => {
      if (evt) {
        evt.preventDefault();
        evt.stopPropagation();
      }

      events.dispatch(EventType.SIGN_IN_STARTED, {
        method: SignInMethodTypes.GOOGLE,
      });

      if (isMobileApp) {
        return sendMessageToApp({
          type: MessageType.TRIGGER_SIGN_IN_WITH_GOOGLE,
          payload: {
            intent: newIntent || intent,
            ...(nav.options?.group_to_join && { group_to_join: nav.options?.group_to_join }),
          },
        });
      }

      // If in a mobile browser, use the OAuth flow instead of One Tap when a user clicks on
      // "Continue with Google". One Tap always renders on the bottom of the screen in mobile
      // browsers, which causes a bad UX.
      if (getUserAgentType() === UserAgentType.mobile || isBottomSheetEnabled) {
        return authenticate({
          purpose: opts?.purpose,
          intent,
          setError,
          setSignInState,
          groupToJoin: nav.options?.group_to_join,
        });
      }

      try {
        const canShowOneTap = await probeOneTap({
          promptParentId: 'rph-sign-in-with-google-probe-parent',
        });
        if (canShowOneTap) {
          if (isInIframe()) {
            setSignInState({ step: LoginStep.WAITING });
            setLoginType('google');
          }
          return authenticate({
            purpose: opts?.purpose,
            intent: newIntent || intent,
            setError,
            setSignInState,
            groupToJoin: nav.options?.group_to_join,
          });
        }
        // The probe succeeded and indicates that we can continue with displaying One Tap.
        navTo('/google', '/account/login', {
          use_modal: true,
          opts: {
            purpose: opts?.purpose,
            group_to_join: nav.options?.group_to_join,
          },
        });
      } catch (err) {
        logger.error('[Google Sign-in] Failed to sign in:', err);
      }
    },
    [isMobileApp, isBottomSheetEnabled, intent, nav.options?.group_to_join, authenticate, probeOneTap, navTo]
  );

  const signInAnonymously = useCallback(async ({ evt }: { evt?: Event } = {}) => {
    if (evt) {
      evt.preventDefault();
      evt.stopPropagation();
    }

    setLoginType('anonymous');
  }, []);

  const oauth2SignIn = useCallback(
    async ({ evt, opts }: { evt?: Event; opts?: { method: string } } = {}) => {
      if (evt) {
        evt.preventDefault();
        evt.stopPropagation();
      }

      if (!opts?.method) {
        throw new Error('No OAuth2 method specified, even though one is required.');
      }

      const authorizeUrl = config?.oauth2AuthorizeUrl?.replace('{provider}', opts?.method.replace('oauth2_', ''));
      let redirectUri = nav?.options?.post_login_redirect || state.config?.postLoginUrl || window.location.href;

      if (!redirectUri.match(/^[a-zA-Z]+:\/\//) && redirectUri?.toUpperCase() !== 'NATIVE_APP') {
        redirectUri = window.location.origin + redirectUri;
      }

      window.location.assign(
        `${authorizeUrl}?app_key=${config?.appKey}&redirect_uri=${redirectUri}${
          instantUserId ? `&instant_user_id=${instantUserId}` : ''
        }`
      );
    },
    [
      config?.appKey,
      config?.oauth2AuthorizeUrl,
      instantUserId,
      nav?.options?.post_login_redirect,
      state.config?.postLoginUrl,
    ]
  );

  useEffect(() => {
    if (loginType === 'anonymous' && signInState.step === LoginStep.INIT) {
      initLogin();
      setSignInState({ step: LoginStep.WAITING });
    }
  }, [initLogin, loginType, signInState.step]);

  const triggerSignUpMethod = useCallback(
    (method: keyof SignInMethods) => {
      setSignInState({ step: LoginStep.INIT });
      setToken(null);
      setIntent(RequestSignInIntent.SignUp);
      switch (method) {
        case 'apple':
          appleIdSignIn({ newIntent: RequestSignInIntent.SignUp });
          break;
        case 'google':
          googleSignIn({ newIntent: RequestSignInIntent.SignUp });
          break;
        case 'passkeys':
          logger.warn('Sign up with Passkey is not currently supported.');
          break;
        case 'crypto_wallet':
          setSignInState({ step: LoginStep.CHOOSE_WALLET_PROVIDER });
          break;
        case 'email':
          setSelectedMethod('email');
          if (!isValidEmail()) {
            setUserIdentifier('');
          }
          break;
        case 'phone':
          setSelectedMethod('phone');
          if (!isValidPhone()) {
            setUserIdentifier('');
          }
          break;
        case 'anonymous':
          signInAnonymously();
      }
    },
    [appleIdSignIn, googleSignIn, isValidEmail, isValidPhone, signInAnonymously]
  );

  // Determine if an auth method is selected as hidden in the app-config
  const isMethodHidden = useMemo((): boolean => {
    return (
      !!orderArray &&
      orderArray.some((method) => {
        return method?.hidden && signInMethodsEnabled[method.name];
      })
    );
  }, [signInMethodsEnabled, orderArray]);

  const showTryAnotherWayButton = useMemo((): boolean => {
    if (isOneMethodEnabled) return false;
    if (intent === RequestSignInIntent.SignUp || intent === RequestSignInIntent.SignIn) return false; // Don't show Try another way with Sign-in or Sign-up
    if (!orderArray || orderArray.length < 1) {
      return !!selectedMethod;
    }

    return (isMethodHidden || !!selectedMethod) && !showAllMethods;
  }, [isOneMethodEnabled, intent, orderArray, isMethodHidden, selectedMethod, showAllMethods]);

  const dynamicAuthMethods = useMemo((): (preact.JSX.Element | undefined)[] => {
    let authMethodTypeArray: ('button' | 'input')[] = ['button', 'input'];

    // Remove input if allowedIdentifiers is empty
    if (allowedIdentifiers?.length < 1) {
      authMethodTypeArray = authMethodTypeArray.filter((type) => type !== 'input');
    }

    // Remove buttons if no additionalSignInMethods
    if (!additionalSignInMethods && !showAllMethods) {
      authMethodTypeArray = authMethodTypeArray.filter((type) => type !== 'button');
    }

    if (orderArray && orderArray.length > 0) {
      // Filter orderArray by only unique types (button, input, etc...)
      authMethodTypeArray = orderArray
        .flatMap((method) => {
          if (showAllMethods) return 'button';
          if (intent === RequestSignInIntent.SignIn && signInMethodsEnabled[method.name]) return method.type;
          if (
            (!method?.hidden || selectedMethod === method.name) &&
            signInMethodsEnabled[method.name.startsWith('oauth2_') ? 'oauth2' : method.name]
          )
            return method.type;
          return [];
        })
        ?.filter((value, index, self) => {
          return self.indexOf(value) === index;
        });

      // Hide input if showing all methods
      if (showAllMethods) {
        authMethodTypeArray = authMethodTypeArray.filter((type) => type !== 'input');
      }
    }

    // Hide button if phone/email are selected
    if (selectedMethod && INPUT_SIGN_IN_METHODS.includes(selectedMethod)) {
      authMethodTypeArray = authMethodTypeArray.filter((type) => type !== 'button');
    }

    // Hide input if apple/google/wallet are selected
    if (selectedMethod && BUTTON_SIGN_IN_METHODS.includes(selectedMethod)) {
      authMethodTypeArray = authMethodTypeArray.filter((type) => type !== 'input');
    }

    const authMethodOptions: Record<'input' | 'button', preact.JSX.Element> = {
      button: (
        <LoginButtons
          passkeySignIn={passkeySignIn}
          appleIdSignIn={appleIdSignIn}
          googleSignIn={googleSignIn}
          anonymousSignIn={signInAnonymously}
          oauth2SignIn={oauth2SignIn}
          setPrevSteps={setPrevSteps}
          prevSteps={prevSteps}
          setSignInState={setSignInState}
          verifyWalletBtnState={verifyWalletBtnState}
          isSubmittingWeb3={isSubmittingWeb3}
          initialStep={initialStep}
          showAllMethods={showAllMethods}
          selectedMethod={selectedMethod}
          setSelectedMethod={setSelectedMethod}
          setShowAllMethods={setShowAllMethods}
          intent={intent}
        />
      ),
      input: (
        <LoginInput
          setUserIdentifier={setUserIdentifier}
          userIdentifier={userIdentifier}
          isSubmitting={isSubmitting}
          loginType={loginType}
          fieldError={fieldError}
          phoneDetails={phoneDetails}
          validateInput={validateInput}
          inputRef={inputRef}
          addlFieldValues={addlFieldValues}
          handleAddlFieldChange={handleAddlFieldChange}
          isValidUserIdentifier={isValidUserIdentifier}
          requiresAdditionalFields={requiresAdditionalFields}
          selectedMethod={selectedMethod}
          intent={intent}
        />
      ),
    };

    return authMethodTypeArray.map((type, idx) => {
      return (
        // eslint-disable-next-line react/jsx-key
        <>
          {idx > 0 && <p className="rph-text-line">OR</p>}
          {authMethodOptions[type]}
        </>
      );
    });
  }, [
    allowedIdentifiers,
    additionalSignInMethods,
    showAllMethods,
    orderArray,
    selectedMethod,
    passkeySignIn,
    appleIdSignIn,
    googleSignIn,
    signInAnonymously,
    oauth2SignIn,
    signInMethodsEnabled,
    prevSteps,
    verifyWalletBtnState,
    isSubmittingWeb3,
    initialStep,
    intent,
    userIdentifier,
    isSubmitting,
    loginType,
    fieldError,
    phoneDetails,
    validateInput,
    addlFieldValues,
    isValidUserIdentifier,
    requiresAdditionalFields,
  ]);

  const dynamicSignInModalTitle = useMemo((): string => {
    if (intent === RequestSignInIntent.SignIn) return signInModal?.sign_in_title || t('Sign into an existing account');
    if (intent === RequestSignInIntent.SignUp) return signInModal?.sign_up_title || t('Sign up');
    if (signInModalTitle) return signInModalTitle;
    return t('Sign in or sign up');
  }, [signInModalTitle, intent, t, signInModal]);

  const dynamicSignInModalSubTitle = useMemo((): string | undefined => {
    if (intent === RequestSignInIntent.SignIn) return signInModal?.sign_in_subtitle || signInModal?.subtitle;
    if (intent === RequestSignInIntent.SignUp) return signInModal?.sign_up_subtitle;
    return nav.options?.subtitle || signInModal?.subtitle;
  }, [intent, signInModal, nav.options?.subtitle]);

  useEffect(() => {
    (async () => {
      // For now, we're excluding iOS devices from browser autofill b/c of this bug:
      // https://bugs.webkit.org/show_bug.cgi?id=250589
      // Do not remove until this is released in a stable iOS 16.x release.
      // Unclear whether this was backported to iOS 15.x, which seemingly supports passkeys in some fashion.
      if (signInMethodsEnabled?.passkeys && !/iP(ad|od|hone)/i.test(window.navigator.userAgent)) {
        let authPayload: AuthenticationResponseJSON;
        try {
          authPayload = await passkeysStartAuthentication(true);
        } catch (err) {
          // If things fail at this point, just bail out. We don't want to throw any errors since
          // this is all done async.
          return;
        }

        // If we get this far, the user has taken a passkey-related action, so now they need UI feedback.
        setLoginType('passkeys');
        setSignInState({ step: LoginStep.WAITING });
        try {
          events.dispatch(EventType.SIGN_IN_STARTED, {
            method: SignInMethodTypes.PASSKEYS,
          });
          await passkeysAuthenticate({ result: authPayload, groupToJoin: nav.options?.group_to_join });
          setSignInState({ step: LoginStep.SUCCESS });
          events.dispatch(EventType.SIGN_IN_COMPLETED, {
            method: SignInMethodTypes.PASSKEYS,
            user_type: UserType.ExistingUser,
          });
        } catch (e) {
          if (e instanceof RowndApiError) {
            if (e.code === 'E_USER_PROFILE_DISABLED') {
              setSignInState({ step: LoginStep.ERROR });
              setError(t('This account is currently disabled. Please try a different account or contact {{support_email}}', {
                support_email: state.app.config?.hub?.legal?.support_email || ROWND_LINKS.SUPPORT_EMAIL
              }));
              return;
            }
          }
          const err = e as Error;
          setSignInState({ step: LoginStep.ERROR });
          setError((err as Error).message || 'Unable to verify your passkey.');
        }
      }
    })();
  }, [nav.options?.group_to_join, passkeysAuthenticate, passkeysStartAuthentication, signInMethodsEnabled?.passkeys]);

  useEffect(() => {
    if (!signInMethodsEnabled?.passkeys) {
      return;
    }

    passkeysEagerlyRequestAuthenticationChallenge();
  }, [passkeysEagerlyRequestAuthenticationChallenge, signInMethodsEnabled?.passkeys]);

  useEffect(() => {
    if (state.use_modal === true && signInState.step === LoginStep.INIT) {
      setIsSendingMetrics(true);
    }
  }, [state.use_modal, signInState.step]);

  useEffect(() => {
    if (isSendingMetrics === true) {
      let signInMethods: string[];
      if (selectedMethod) {
        signInMethods = [selectedMethod];
      } else {
        signInMethods = orderArray?.filter((item) => !item.hidden).map((item) => item.name) || [];
      }
      const metric = {
        event: MetricEvent.INITIATE_SIGN_IN,
        step: signInState.step,
        signInModalTitle: dynamicSignInModalTitle,
        signInMethods,
        isTryAnotherWayButtonVisible: showTryAnotherWayButton,
      };
      collectMetric(metric);
      setIsSendingMetrics(false);
    }
  }, [
    collectMetric,
    dynamicSignInModalTitle,
    isSendingMetrics,
    orderArray,
    selectedMethod,
    showTryAnotherWayButton,
    signInState.step,
  ]);

  const onProviderChosen = useCallback(
    async (providerInfo: ProviderInfo, e?: Event) => {
      if (providerInfo.hideWhenSigningIn) {
        handleClose();
      }
      switch (providerInfo.type) {
        case ProviderType.Ethers: {
          setPrevSteps([...prevSteps, LoginStep.CHOOSE_WALLET_PROVIDER]);
          setSignInState({ step: LoginStep.WAITING_FOR_WALLET_PROVIDER });
          if (!providerInfo.ethersProvider) {
            return logger.error('Mising ethersProvider in provider info:', providerInfo);
          }
          initWeb3(e as Event, providerInfo.ethersProvider);
          break;
        }
        case ProviderType.Near: {
          setPrevSteps([...prevSteps, LoginStep.CHOOSE_WALLET_PROVIDER]);
          setSignInState({ step: LoginStep.WAITING_FOR_WALLET_PROVIDER });

          const walletId = providerInfo.id;
          const wallet = await near.selector?.wallet(walletId);
          const signInResult = await wallet?.signIn({
            contractId: near.config?.network_id === 'testnet' ? 'auth.rownd.testnet' : 'auth.rownd.near',
            methodNames: [],
            accounts: [],
          });

          logger.debug('Signed into NEAR wallet', signInResult);
          break;
        }
        default: {
          logger.error(`Unknown wallet provider '${providerInfo.type}'`);
        }
      }
    },
    [handleClose, initWeb3, near.config?.network_id, near.selector, prevSteps]
  );

  const LoginFooter = useMemo(() => {
    return (
      <>
        <p className="rph-legal-footer">
          <Trans
            i18nKey="By continuing, you agree to company_name's terms and conditions and privacy policy."
            values={{ companyName }}
            components={[
              termsConditions ? (
                <a target="_blank" rel="noopener noreferrer" href={termsConditions}>
                  {' '}
                  terms and conditions{' '}
                </a>
              ) : (
                <> terms and conditions </>
              ),
              privacyPolicy ? (
                <a target="_blank" rel="noopener noreferrer" href={privacyPolicy}>
                  {' '}
                  privacy policy{' '}
                </a>
              ) : (
                <> privacy policy </>
              ),
            ]}
          />
        </p>
        <button className="rph-text-powered-by" onClick={() => setPopupRoute('/powered')}>
          {t('Powered by Rownd')}
        </button>
      </>
    );
  }, [companyName, privacyPolicy, setPopupRoute, t, termsConditions]);

  const LoginCloseControl = useMemo(() => {
    if (
      nav.options?.prevent_closing ||
      ([LoginStep.WAITING, LoginStep.VERIFY].includes(signInState.step) && !allowUnverifiedUsers)
    ) {
      return null;
    }
    return <CloseControl onClose={onClose} disable={isMobileApp} />;
  }, [nav.options?.prevent_closing, signInState.step, allowUnverifiedUsers, onClose, isMobileApp]);

  if (state.app.invalid_web_origins) {
    return (
      <>
        {LoginCloseControl}
        <div className="rph-login rph-modal">
          {app?.config?.hub?.auth?.show_app_icon && appIcon && (
            <div className="rph-login__app-icon">
              <img src={appIcon} alt="Site logo" />
            </div>
          )}
          <h2 className="rph-login__title">{state.use_modal ? dynamicSignInModalTitle : t('Sign up or sign in')}</h2>
          <UnauthorizedWebOrigins />
          {LoginFooter}
        </div>
      </>
    );
  }

  return (
    <>
      {LoginCloseControl}
      {prevSteps.length > 0 && <BackControl onBack={goBack} />}
      <div className="rph-login rph-modal">
        {[LoginStep.SUCCESS, LoginStep.COMPLETING, LoginStep.WAITING, LoginStep.VERIFY].includes(signInState.step) &&
          !isBottomSheetEnabled &&
          visualSwoops &&
          !isMobileApp && <SwoopsAnimation />}
        {signInState.step === LoginStep.INIT && (
          <>
            {app?.config?.hub?.auth?.show_app_icon && appIcon && (
              <div className="rph-login__app-icon">
                <img src={appIcon} alt="Site logo" />
              </div>
            )}
            <h2 className="rph-login__title">{state.use_modal ? dynamicSignInModalTitle : t('Sign up or sign in')}</h2>
            {dynamicSignInModalSubTitle && !(selectedMethod && isPreviousMethod) && (
              <p className="rph-login__subtitle">{dynamicSignInModalSubTitle}</p>
            )}
            {selectedMethod && isPreviousMethod && (
              <p className="rph-login__subtitle">
                {t('You signed in with {{selectedMethod}} most recently', {
                  selectedMethod: selectedMethod.charAt(0).toUpperCase() + selectedMethod.slice(1),
                })}
              </p>
            )}
            {!!state.app.config && (
              <>
                <div className="rph-login-form">
                  <form onSubmit={initLogin}>
                    {dynamicAuthMethods.map((method) => method)}
                    {showTryAnotherWayButton && (
                      <Button
                        handleOnClick={() => {
                          setShowAllMethods(true);
                          setSelectedMethod(null);
                          setIsPreviousMethod(false);
                        }}
                        customClass="rph-login__button-different-method"
                        type="text"
                        label={t('Try another way')}
                      />
                    )}
                    {app.config?.hub?.auth?.use_explicit_sign_up_flow === true &&
                      (intent === RequestSignInIntent.SignIn || intent === RequestSignInIntent.SignUp) && (
                        <Button
                          label={
                            intent === RequestSignInIntent.SignIn
                              ? signInModal?.sign_up_button || t('Sign up')
                              : signInModal?.sign_in_button || t('Sign into an existing account')
                          }
                          customClass="rph-login__button-sign-in-up"
                          handleOnClick={() =>
                            setIntent(
                              intent === RequestSignInIntent.SignIn
                                ? RequestSignInIntent.SignUp
                                : RequestSignInIntent.SignIn
                            )
                          }
                          type="text"
                        />
                      )}
                  </form>
                </div>
                {LoginFooter}
              </>
            )}
          </>
        )}

        {signInState.step === LoginStep.CHOOSE_WALLET_PROVIDER && (
          <ChooseWalletProvider
            onProviderChosen={onProviderChosen}
            providerScopes={state.nav.options?.wallet_provider_scopes}
          />
        )}

        {signInState.step === LoginStep.WAITING_FOR_WALLET_PROVIDER && (
          <SignInLoading title={t('Waiting for wallet provider...')} />
        )}

        {signInState.step === LoginStep.CONNECT_ACCOUNT && (
          <div className="rph-connect-account">
            <h2 className="rph-connect-account__title">{t('Add new sign in method')}</h2>
            <p className="rph-connect-account__subtitle">
              {t('Increase the security of your profile by adding another way of signing in.')}
            </p>
            {signInMethodsEnabled['apple'] && !user.data['apple_id'] && (
              <button
                className="rph-signin-btn"
                onClick={(evt: Event) => appleIdSignIn({ evt, opts: { purpose: 'connect_account' } })}
              >
                {' '}
                <span className="rph-signin-btn__appleid" />
                {t('Connect Apple account')}
              </button>
            )}
            {signInMethodsEnabled['google'] && !user.data['google_id'] && (
              <>
                <button
                  className="rph-signin-btn"
                  onClick={(evt: Event) => googleSignIn({ evt, opts: { purpose: 'connect_account' } })}
                >
                  {' '}
                  <span className="rph-signin-btn__google" data-type="standard" />
                  {t('Connect Google account')}
                </button>
                <div id="rph-sign-in-with-google-probe-parent" style="display: none !important;" />
              </>
            )}
            {signInMethodsEnabled['crypto_wallet'] && !user.data['crypto_wallet_address'] && (
              <button
                disabled={verifyWalletBtnState}
                type="button"
                className="rph-signin-btn"
                onClick={() => {
                  setPrevSteps([...prevSteps, initialStep]);
                  setSignInState({ step: LoginStep.CHOOSE_WALLET_PROVIDER });
                }}
              >
                <span className="rph-signin-btn__wallet" />
                {isSubmittingWeb3 ? t('Waiting on provider...') : t('Connect Web3 wallet')}
              </button>
            )}
            <Button
              label="Cancel"
              type="text"
              customClass="rph-connect-account__cancel-button"
              handleOnClick={() => {
                navTo('/account/manage', void 0, { use_modal: true, is_container_visible: true });
              }}
            />
            <p className="rph-text-powered-by">
              <Trans
                i18nKey="Powered by Rownd"
                components={{
                  a: (
                    <a href={RowndHomePage} target="_blank" rel="noreferrer">
                      Rownd
                    </a>
                  ),
                }}
              />
            </p>
          </div>
        )}

        {(signInState.step === LoginStep.VERIFY || signInState.step === LoginStep.WAITING) &&
          loginType &&
          ['email', 'phone'].includes(loginType) && (
            <>
              <h2 className="rph-login-waiting__title">
                {t(verificationModalTitle || 'Thanks! Verify your {{identifier}} to finish', {
                  identifier: loginType === 'phone' ? t('phone number') : t('email'),
                })}
              </h2>
              <p className="rph-login-waiting__subtitle">
                <Trans
                  t={t}
                  i18nKey="Click the link in the message we just sent to <1>{{userIdentifier}}</1> to verify and finish."
                  values={{ userIdentifier }}
                >
                  Click the link in the message we just sent to <strong>{{ userIdentifier }}</strong> to verify and
                  finish.&nbsp;
                </Trans>
              </p>
              {!hideVerificationIcons && (
                <div className="rph-login-waiting-image-container">
                  <div
                    className="rph-login-waiting-image-background"
                    style={`background-color: ${computed_color_mode === 'dark' ? 'black' : primaryColor} !important`}
                  />
                  <div
                    className={classnames('rph-login-waiting-image', { 'rph-login-phone': loginType === 'phone' })}
                  />
                </div>
              )}
              <div className="rph-login-waiting-footer">
                <Button
                  customClass="rph-login-waiting__button-resend"
                  label={
                    isResendingMessage
                      ? t('Resending...')
                      : didResendMessage
                      ? t('Message was re-sent')
                      : t('Re-send message')
                  }
                  hide={!canResendMessage}
                  type="tertiary"
                  handleOnClick={resendLinkClick}
                />
                {initialStep === LoginStep.VERIFY ? (
                  <button className="rph-button-link rph-login-waiting-button" onClick={cancelVerify}>
                    {t('Cancel')}
                  </button>
                ) : (
                  <>
                    {isMobileApp &&
                      (loginType === 'email' || !loginType) &&
                      isFeatureEnabled({ storage: sessionStorage, feature: FeatureFlagTypes.OpenEmailInbox }) && (
                        <a
                          className="button rph-login-waiting__button-open-email"
                          target="_blank"
                          rel="noopener noreferrer"
                          href="mailto:"
                          onClick={() => sendMessageToApp({ type: MessageType.OPEN_EMAIL_APP })}
                        >
                          {t('Open email app')}
                        </a>
                      )}
                    <button
                      className="rph-button-link rph-button-link-secondary rph-login-waiting-button"
                      onClick={() => setSignInState({ step: LoginStep.INIT })}
                    >
                      {t('Try a different {{identifier}}', {
                        identifier: loginType === 'phone' ? t('phone number') : t('email'),
                      })}
                    </button>
                  </>
                )}
              </div>
            </>
          )}

        {signInState.step === LoginStep.WAITING && loginType && !['email', 'phone'].includes(loginType) && (
          <SignInLoading
            title={t(loginType === 'passkeys' ? 'Waiting for passkey...' : 'Waiting for authentication...')}
            showTitle={loginType !== 'anonymous'}
          />
        )}

        {signInState.step === LoginStep.COMPLETING && (
          <SignInSuccess completingSignIn={true} isExistingUser={isExistingUser} />
        )}

        {signInState.step === LoginStep.SUCCESS && (
          <SignInSuccess
            completingSignIn={signInState.waitingForPostSignInApi}
            isExistingUser={isExistingUser}
            message={nav.options?.success_message}
          />
        )}

        {[LoginStep.ERROR, LoginStep.FAILURE].includes(signInState.step) && (
          <SignInError
            type={loginType}
            reason={error}
            primaryActionCb={() => {
              setError('');
              setSignInState({ step: LoginStep.INIT });
            }}
          />
        )}

        {signInState.step === LoginStep.NO_ACCOUNT && (
          <>
            <div className="rph-login-no-account__title">
              {noAccountMessage?.title || t('No existing account found')}
            </div>
            <div className="rph-login-no-account__subtitle">
              <Trans
                i18nKey="We couldn’t find an account for <1>{{userIdentifier}}</1>. Please try a different method, continue with new account, or"
                values={{ userIdentifier }}
              >
                We couldn’t find an account for <em style="word-break: break-all !important;">{{ userIdentifier }}</em>.
                Please try a different method, continue with new account, or
              </Trans>
              <a target="_blank" rel="noopener noreferrer" href={`mailto:${supportEmail}`}>
                {' '}
                {t('contact support')}
              </a>
              .
            </div>
            <Button
              label={t('Try a different sign in method')}
              handleOnClick={() => {
                setSignInState({ step: LoginStep.INIT });
                setUserIdentifier('');
                setToken(null);
              }}
            />
            {primarySignUpMethod && primarySignUpMethod !== loginType ? (
              <Button
                customClass="rph-login-no-account__button-continue"
                label={t(`Sign up with {{method}} instead`, {
                  method: primarySignUpMethod.charAt(0).toUpperCase() + primarySignUpMethod.slice(1),
                })}
                type="text"
                handleOnClick={() => triggerSignUpMethod(primarySignUpMethod)}
              />
            ) : (
              <Button
                customClass="rph-login-no-account__button-continue"
                label={t('Continue with new account')}
                type="text"
                isLoading={isSubmitting}
                handleOnClick={() => {
                  if (token) {
                    initTokenLogin();
                  } else {
                    initLogin(undefined, true);
                  }
                }}
              />
            )}
            <button className="rph-login-no-account__footer" onClick={() => setPopupRoute('/powered')}>
              {t('Powered by Rownd')}
            </button>
          </>
        )}
      </div>
    </>
  );
}
